.postCard {
    display: flex;
    flex-direction: column;
    gap: 8px;
    background-color: #171717;
    border-radius: 12px;
    padding: 12px 0;

    .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 12px;
    }

    .text {
        display: -webkit-box;
        color: #d4d4d4;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
    }

    .mediaConetent {
        position: relative;
        width: 100%;
        aspect-ratio: 377 / 400;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover !important;
        }

        .duration {
            display: flex;
            align-items: center;
            font-size: 12px;
            position: absolute;
            top: 12px;
            right: 12px;
            color: #d4d4d4;
            background-color: rgba(0 0 0 / 40%);
            padding: 4px 8px;
            line-height: 20px;
            border-radius: 16px;
            z-index: 9;
        }

        .favoriteBox {
            position: absolute;
            bottom: 12px;
            left: 12px;
            width: 40px;
            height: 40px;
            background-color: rgb(0 0 0 / 20%);
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            z-index: 9;

            &.isFollow {
                background-color: #171717;

                svg path {
                    fill: #d4d4d4;
                }
            }
        }
    }

    .bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 16px;

        .infoBox {
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 4px;
            color: #d4d4d4;
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;

            .infoBar {
                display: flex;
                align-items: center;
                column-gap: 8px;

                .info {
                    display: flex;
                    align-items: center;
                    column-gap: 2px;
                    color: #a3a3a3;
                    font-size: 12px;
                    line-height: 16px;
                }
            }

            .time {
                color: #737373;
                font-size: 12px;
                font-weight: 400;
                line-height: 16px;
            }
        }

        .iconBtn {
            cursor: pointer;
        }

        .operateBar {
            display: flex;
            align-items: center;
            column-gap: 8px;
        }
    }

    .chatBar {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 0 16px;
        margin-top: 8px;
        box-sizing: border-box;

        .chatBtn {
            height: 44px;
            padding: 0 16px;
            display: flex;
            align-items: center;
            gap: 8px;
            flex: 1;
            border-radius: 8px;
            border: 1px solid #404040;
            background-color: #262626;
            color: #737373;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            box-sizing: border-box;
            cursor: pointer;
        }

        .giftBtn {
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgb(250 250 250 / 30%);
            border-radius: 50%;
            cursor: pointer;
        }
    }
}
