.profileCard {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 12px;
    border-radius: 12px;
    background-color: #171717;

    .imageBox {
        border-radius: 12px;
        width: 100%;
        display: flex;
        align-items: center;
        gap: 8px;
        overflow: auto;
        touch-action: auto;

        .imgCaed {
            position: relative;
            width: 144px;
            aspect-ratio: 144 / 180;
            flex: 0 0 144px;

            .img {
                width: 144px;
                border-radius: 12px;
            }
        }
    }

    .infoBar {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .right {
            display: flex;
            align-items: center;
            gap: 16px;

            .chatBtn {
                width: 36px;
                height: 36px;
                background-color: rgb(250 250 250 / 20%);
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                column-gap: 4px;
                cursor: pointer;
            }

            .followBox {
                width: 36px;
                height: 36px;
                background-color: rgb(250 250 250 / 20%);
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                &.isFollow {
                    background-color: #450a0a;
                }
            }
        }
    }

    .tagBar {
        height: 32px;
        display: flex;
        align-items: center;
        gap: 8px;
        overflow: auto;
        touch-action: auto;

        .tag {
            background-color: rgb(217 217 217 / 10%);
            display: flex;
            padding: 6px 12px;
            justify-content: center;
            align-items: center;
            border-radius: 16px;
            color: #d4d4d4;
            font-size: 14px;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.28px;
            white-space: nowrap;
        }

        .cpType {
            height: 32px;
            padding: 0 12px 0 8px;
            display: flex;
            align-items: center;
            column-gap: 8px;
            color: #d4d4d4;
            font-size: 14px;
            line-height: normal;
            border-radius: 16px;
            box-sizing: border-box;
            white-space: nowrap;

            &.evil {
                background-color: rgb(171 61 61);
            }

            &.angel {
                background-color: rgb(217 217 217);
                color: #0a0a0a;
            }
        }
    }
}