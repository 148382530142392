.exploreList {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .title {
        margin-bottom: 8px;
        padding: 0 8px;
        color: #d4d4d4;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px; /* 155.556% */
    }

    .listEnd {
        color: #666;
        padding: 16px 0;
        text-align: center;
        font-size: 14px;
        letter-spacing: -0.36px;
        line-height: normal;
    }

    .moreBtn {
        margin-top: 16px;
        color: #d4d4d4;
        border: 1px solid #d4d4d4;
        background-color: initial;
    }

    .loadMore {
        padding: 8px 0;
        display: flex;
        justify-content: center;
    }
}
